<script>
    import Advertising from '@/services/Advertising'
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import modalAddSponsorMedia from "@/components/modals/advertising/modalAddSponsorMedia";
    import ModalAddAdvertisementMediaAttributes from "@/components/modals/advertising/modalAddAdvertisementMediaAttributes";
    import ModalAddAdvertisementAttributes from "@/components/modals/advertising/modalAddAdvertisementAttributes";
    import Teams from '@/services/Teams'

    export default {
        components: {
            FormWizard,
            TabContent,
            modalAddSponsorMedia,
            ModalAddAdvertisementMediaAttributes,
            ModalAddAdvertisementAttributes
        },
        props: ['advertisementData', 'placementTypeData'],

        data() {
            return {
                tryingToEdit: false,
                errMessage: '',
                errMessage2: '',
                isBusy: false,
                showModal: false,
                showLoader:false,
                showLoader2:false,
                placementTypes: [],
                placement_type_id: '',
                placement_status:'',
                sponsors:[],
                sponsor:[],
                sponsor_id:'',
                sponsorMedia:[],
                selectedMedia:[],
                selectedMediaTest:[],
                mediaItem:[],
                attributes:[],

                structure:[],
                structure_attributes:[],
                placement_attributes:[],
                structure_media:[],
                delimiter:"_",
                dropdown_media :[],
                dropdown_media_selected :[],
                thirdTabKey:0,
                media_attributes:[],
                selectedMediaColors: [], 
                selectedPlayer: [] ,
                selectedMatch: '' ,
                attributes_new: [],
                squad: [] ,
                fixtures : [] ,
                filteredMatches : {},
                playerNotSelected : false ,
                MatchNotSelected : false ,
                duplicateAttribute : false ,
                valueToRemove : '' ,
                NameToRemove : '' ,
                submitted: false,

            };
        },


        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.sponsorMedia != null ? this.sponsorMedia.length : 0;
            },
            placementType(){
                if(this.placement_type_id){
                    const type = this.placementTypes.find(s => s.id === this.placement_type_id);
                    return type.name
                }
                return ''
            }
        },
        mounted() {
            //this.totalRows = 0;
            this.getSponsors()           
        },
        methods: {
            /*async getPlacementTypes(){
                this.showLoader = true;
                await Advertising.getPlacementTypes()
                    .then(response => {
                        this.placementTypes = response.data.data
                        if(this.placementTypes.length > 0){
                            this.placement_type_id = this.placementTypes[0].id
                        }
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }).finally(() => {
                        this.showLoader = false;
                    })
            },*/

             async getPlacementTypeStructure(id){
                this.showLoader = true;
                var params = 'placement_type_id='+id;
                await Advertising.getPlacementTypeStructure(params)
                    .then(response => {
                        this.structure = response.data.data
                        this.structure_attributes = response.data.data.structure.attributes;
                        this.structure_media = response.data.data.structure.media;

                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    }).finally(() => {
                        this.showLoader = false;
                    })
            },

            getSponsors(){
                this.showLoader2 = true
                Advertising.getSponsors()
                    .then(response => {
                        this.sponsors = response.data.data;
                        this.sponsors = this.sponsors.filter(sponsor => sponsor.active === 1);

                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.showLoader2 = false
                    })
            },

            validateTab1(){
                var validated = true;
                this.placement_attributes = [];
                var attr_keys = Object.keys(this.structure_attributes);

                var i=0;
                attr_keys.forEach((element) => {
                    var attribute_input = document.getElementById(element);

                        if (!attribute_input || attribute_input.value == '') {
                           this.errMessage = 'Please fill all attributes'
                           validated = false;
                    } else {
                      //  this.placement_attributes[element] = attribute_input.value;
                           this.placement_attributes.push({ key:element, value: attribute_input.value });
                    }
                    i++;
                });


                if(this.sponsor_id.length <= 0){
                    this.errMessage = 'Please choose sponsor';
                    validated = false;
                }

                if(validated){
                    this.errMessage='';
                }

                return validated;

            },

             validateTab2(){
                    var validated = true;
                    this.media_attributes = [];


                    //validate media attributes
                    var i=0;
                    this.structure_media.forEach((element) => {

                       //validate image
                       var image_div = 'image_'+i;

                       if( document.getElementById(image_div).innerHTML === "" ){

                             this.errMessage2 = 'Please select all required photos!'
                             validated = false;
                       }



                        var obj_attr_length = Object.keys(element.attributes).length;

                        if(obj_attr_length !== 0 ) {
                            var attr_keys = Object.keys(element.attributes);

                            attr_keys.forEach((element) => {
                                var element_id= i + '_' + element;

                                var attribute_input = document.getElementById(element_id);
                                    if (!attribute_input || attribute_input.value == '') {
                                       this.errMessage2 = 'Please fill all attributes'
                                       validated = false;
                                } else {
                                       this.media_attributes.push({image:i ,key:element, value: attribute_input.value });
                                }
                            });
                        }
                        i++;
                    });


                    //validate media images


                    if(validated){
                        this.errMessage2='';
                    }


                    return validated;

            },

            chooseMedia(mediaItem, index){
                if(!this.selectedMedia.some(data => data.id === mediaItem.id)){
                    this.selectedMedia.push(mediaItem)
                   // this.selectedMedia[index] = mediaItem;

                }
            },
            callModalAddAdvertisementMediaAttributes(data){
                this.$bvModal.show("add_advertisement_media_attributes");
                this.mediaItem = data
            },
            callModalAddAdvertisementAttributes(data){
                this.placement_type_id = data
                this.$bvModal.show("add_advertisement_attributes");
            },
            callModalAddSponsorMedia() {
                this.$bvModal.show("add_sponsor_media");
            },
            removeAdvertisementMedia(index){
                this.selectedMedia.splice(index, 1);
            },
            onChangeSponsor(){
                if(this.sponsor_id.length){
                    this.errMessage = ''
                    this.errMessage2 = ''
                    this.selectedMedia = []
                    this.dropdown_media_selected = []
                    this.sponsor = this.sponsors.find(s => s.id === this.sponsor_id);
                    this.sponsorMedia = this.sponsor.media


                     const selects = [];
                     var i = 0;
                    this.structure_media.forEach((element) => {

                            this.getSponsorMedia(element.width, element.height, i);
                            i++;


                    });
                }
            },
            getSponsor(){
                this.showLoader = true;
                Advertising.getSponsorById(this.sponsor_id)
                    .then(response => {
                        const sponsorData =  response.data.data[0];
                        this.getSponsors()
                        this.sponsorMedia = sponsorData.media
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.showLoader = false;
                    })
            },

            getSponsorMedia(width, height, index){
               var filter = 'sponsor_id=' + this.sponsor_id + '&width=' + width + '&height=' + height;
              // var filter = 'sponsor_id=' + this.sponsor_id ;
              // console.log(filter);
               Advertising.getSponsorMedia(filter)
                    .then(response => {
                        const sponsorData =  response.data.data[0];

                        const sponsorMediaDataFiltered =  response.data.data;
                        this.dropdown_media[index] = sponsorMediaDataFiltered;

                        this.getSponsors()
                        this.sponsorMedia = sponsorData.media
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.showLoader = false;
                    })
            },

            getMediaWithAttributes(media){
                var foundIndex = this.selectedMedia.findIndex(x => x.id == media.id);
                this.$nextTick(function () {
                    this.$set(this.selectedMedia[foundIndex].attributes, media.attributes)
                    this.$forceUpdate()
                })
            },
            getAdvertisementWithAttributes(items){
                this.attributes = items
                this.$forceUpdate()
            },
            async editAdvertisement(){
                this.tryingToEdit = true;
                var selected_media = this.dropdown_media_selected;

                await Advertising.editSponsorAdvertisement(this.advertisementData.placement_id,{
                    sponsor_id: this.sponsor_id,
                    placement_type_id: this.placement_type_id,
                    placement_attributes: this.placement_attributes,
                    placement_media: this.dropdown_media_selected,
                    placement_media_attributes: this.media_attributes,
                    placement_status: this.placement_status,
                    csrf_token: localStorage.getItem('csrf_token')
                }).then((response) => {
                    const res = response.data ? response.data : false;
                    const error = response.data.error ? response.data.error : '';
                    if(res && error==''){
                        this.successmsg("Advertisement Successfully Changed")
                        this.closeModal()
                    }else{
                        this.failedmsg("Advertisement Change Failed")
                    }
                }).catch(error => {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg('Advertisement Change Failed',this.error)
                }).finally(() => {
                  //  this.$emit('refreshData');
                    this.tryingToEdit = false;
                })
            },
            closeModal() {
                this.showModal = false;
                this.dropdown_media_selected = [];
                this.selectedMediaColors = [];
                this.placement_attributes = [];
                this.$emit('refreshData');
                this.tryingToEdit = false;

            },
            resetData(){
                this.sponsor=[]
                this.sponsor_id=''
                this.sponsorMedia=[]
                this.selectedMedia=[]
                this.mediaItem=[]
                this.attributes=[]
                this.showLoader = false
                this.showLoader2 = false
            },
            modalShownCalls(){
                //this.getPlacementTypes()

                this.getSponsors()
                this.getPlacementTypeStructure(this.advertisementData.placement_type);
                this.placement_type_id = this.placementTypeData.id;
                this.syncData();
                this.syncMediaData();
                this.getSquad();
                this.getFixtures();


            },

           async selectImage(index, item){
                this.dropdown_media_selected.forEach((element) => { 
                    if(element.key == index){
                       const index_delete = this.dropdown_media_selected.indexOf(element);
                       this.dropdown_media_selected.splice(index_delete, 1);
                    }
               });

                this.dropdown_media_selected.push({ key:index, value: item });
                var element_id = 'image_' + index;
            var content = '<img class="img-fluid" src="'+item.source_url + '" />';
            document.getElementById(element_id).innerHTML = content;
            if (item.attributes && item.attributes.color) {
                this.selectedMediaColors[index] = item.attributes.color; 
            } else {
                this.selectedMediaColors[index] = '#f8f9fa'; 
            }

            },
                  syncData(){
                this.attributes = [];
                var structure = this.advertisementData.structure;

                this.placement_type_id = this.advertisementData.placement_type
                this.placement_status = this.advertisementData.placement_active
                this.dropdown_media_selected = [];
                this.selectedMediaColors = [];

                if(this.advertisementData.media[0]){
                    this.sponsor_id = this.advertisementData.media[0].sponsor_id
                    this.sponsor = this.sponsors.find(s => s.id === this.advertisementData.media[0].sponsor_id);

                }

        if(structure.attributes){
                    var player_id = '';
                    var match_id = '';
                    Object.entries(structure.attributes).forEach(structure_entry => {

                        const [structure_key, structure_value] = structure_entry;
                        var attr_value =   this.advertisementData.attributes[structure_key];
                        if(structure_value.type == "player_id") {
                             player_id = attr_value;
                         }
                        if(structure_value.type == "match_id") {
                          match_id = attr_value;
                      }
                         this.attributes.push({name: structure_key, value: attr_value, type: structure_value.type})

                    });
                     this.selectedPlayer = player_id;
                     this.selectedMatch = match_id;
                }

               /* if(this.advertisementData.attributes){
                    var player_id = '';

                    Object.entries(this.advertisementData.attributes).forEach(entry => {
                        const [key, value] = entry;
                         var attribute_type = structure.attributes[key].type;
                         if(attribute_type == "player_id") {
                             player_id = value;
                         }
                        this.attributes.push({name: key, value: value, type: attribute_type})
                    });
                     this.selectedPlayer = player_id;
                } */

  if(this.advertisementData.media){
                    Object.entries(this.advertisementData.media).forEach(entry => {
                        const [key, value] = entry;
                        this.getSponsorMedia(value.width, value.height, key);
                        this.dropdown_media_selected.push({key: key, value: value});
                     
                        if (value.attributes && value.attributes.color) {
                this.selectedMediaColors[key] = value.attributes.color; 
            } else {
                this.selectedMediaColors[key] = '#f8f9fa'; 
            }
                    });
                    
                }

                this.$forceUpdate()
            },

            updateColor(index, color) {
            this.$set(this.selectedMediaColors, index, color); 
        },
       
    syncMediaData(sponsorData) {

              /*  Object.entries(this.advertisementData.media).forEach(entry => {
                    const [key, value] = entry;
                    const media_id = value.id
                    const sponsorMediaData = sponsorData.media.find(s => s.id === media_id);
                    const attributes = []

                    Object.entries(value.attributes).forEach(entry_atr => {
                        const [key_atr, value_atr] = entry_atr;
                        if(key_atr != 'width' && key_atr != 'height' && key_atr != 'text' && key_atr != 'source_url'){
                            attributes.push({name: key_atr, value: value_atr})
                        }
                    });

                    sponsorMediaData['attributes'] = attributes
                    this.selectedMedia.push(sponsorMediaData)
                }); */
            },

            onChangePlayer(index){

               //document.getElementsByClassName('player_id')[0].value = this.selectedPlayer.id;
               //document.getElementById(index).value = this.selectedPlayer.first_name + ' ' + this.selectedPlayer.last_name;
               this.playerNotSelected = null ;

            },
          onChangeMatch(){

              //  this.attributes[0].name = 'match_id';
               // this.attributes[0].value = this.selectedMatch;
                this.MatchNotSelected = null ;

            },

             getSquad() {
                const hardcodedParam = 'all';
                Teams.getTeamSquad(hardcodedParam).then(response => {
                  response.data.squad.forEach((squads) => {
                             squads.players.forEach((player) => {
                             this.squad.push(player);
                       });
                  });

                 }).catch(error => {
                   console.error('Error fetching team squad:', error);
               });
            },
            async getFixtures() {
                try {
                    const response = await Teams.getFixtures();
                    this.fixtures1 = response.data.fixtures;

                    // Filter out  with "past" equal to "PAST"
                    this.filteredMatches = this.fixtures1.filter(obj => obj.past !== "PAST");

                } catch (error) {
                    console.error('Error fetching team fixtures:', error);
                }
            },


        }
    };
</script>


<template>
    <b-modal @hidden="resetData" @shown="modalShownCalls" id="edit_advertisement_new" size="xl" v-model="showModal" title="Edit Advertisement" title-class="font-18" hide-footer>

        <div class="row" v-if="showModal">
            <div class="col-12">
                <form-wizard color="#5b73e8">
                    <tab-content icon="uil uil-expand-arrows" :before-change="validateTab1">
                        <template v-if="!showLoader">

                            <b-form-group class="mt-5">
                                <div class="row">
                                    <h5 class="text-center">Choose Sponsor</h5><span class="small text-danger d-inline-block">{{errMessage}}</span>
                                    <template v-if="!showLoader2">
                                        <v-app class="sponsors-app">
                                            <v-sheet >
                                                <v-slide-group show-arrows >
                                                    <v-row>
                                                        <v-col v-for="sponsor in sponsors" :key="sponsor.id" >
                                                            <b-card no-body>
                                                                <b-card-body>
                                                                    <b-card-title class="text-center">
                                                                        <b-form-radio
                                                                                v-model="sponsor_id"
                                                                                :aria-describedby="sponsor.name"
                                                                                name="sponsor"
                                                                                :value="sponsor.id"
                                                                                @change="onChangeSponsor"
                                                                        >
                                                                            <h4 class="ms-2 card-title"><strong>{{sponsor.name}}</strong></h4>
                                                                        </b-form-radio>

                                                                        <h6 class="card-subtitle text-muted">
                                                                            Status:
                                                                            <span v-html="format_status(sponsor.active ? 'active' : 'inactive', 'font-size-12')"></span>
                                                                        </h6>
                                                                    </b-card-title>

                                                                    <div class="sponsor-logo-grid-small text-center">
                                                                        <img class="img-fluid img-thumbnail" :src="sponsor.logo" />
                                                                    </div>
                                                                </b-card-body>
                                                            </b-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-slide-group>
                                            </v-sheet>
                                        </v-app>

                                        <div v-if="sponsors.length <= 0">
                                            <p class="text-center mt-3">Sponsors not found</p>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader2">
                                            <b-spinner large></b-spinner>
                                        </div>
                                    </template>
                                </div>
                            </b-form-group>

                            <div class="row mt-2">
                                 <div class="col-6">
                                    <h5>Add Attributes</h5>
                                     <div class="col-sm-6" v-for="(item, index) in attributes" :key="index">
                                       <!--   <b-form-group :label="index" label-for="attributename">
                                                 <b-form-input :id="index" :name="index" :value="item"> </b-form-input>
                                           </b-form-group> -->

                                           <b-form-group :label="item.name" label-for="attributename">
                                                <template v-if="item.type === 'player_id'">
                                                        <select :id="item.name" v-model="selectedPlayer"
                                                        @change="onChangePlayer(item.name)"
                                                         class="form-control form-select" type="select">
                                                            <option value="">Select a player</option>
                                                            <option v-for="player in squad" :key="player.id" :value="player.id" >
                                                                {{ player.first_name }} {{ player.last_name }}
                                                            </option>
                                                        </select>

                                                </template>

                                                <!-- <template v-else-if="item.type === 'player_id'">
                                                    <b-form-input :id="item.name" :name="item.name" class="player_id" :value="item.value" readonly> </b-form-input>
                                                 </template> -->

                                                <template v-else-if="item.type === 'match_id'">
                                                   <select :id="item.name" v-model="selectedMatch" @change="onChangeMatch()" class="form-control form-select mb-4" type="select">
                                                        <option value="">Select Match</option>
                                                        <option v-for="match in filteredMatches" :key="match.match_id" :value="match.match_id" >
                                                            {{ match.home_team_short }} : {{ match.away_team_short }} -  {{match.date_formatted}}
                                                        </option>

                                                    </select>
                                                       <!--<input type="hidden" :id="index" :name="index">-->

                                                </template>

                                                <template v-else>
                                                   <b-form-input :id="item.name" :name="item.name" :value="item.value"> </b-form-input>
                                                </template>
                                          </b-form-group>
                                      </div>
                                 </div>
                                 <div class="col-6">
                                     <h5>Active Status</h5>
                                     <b-form-checkbox-group >
                                         <div class="form-check form-switch form-switch-lg mb-3" >
                                             <input v-model="placement_status"
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    id="switch1"
                                                    true-value="1"
                                                    false-value="0"
                                             />
                                         </div>
                                     </b-form-checkbox-group>
                                 </div>

                            </div>


                        </template>
                        <template v-else>
                            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                                <b-spinner large></b-spinner>
                            </div>
                        </template>
                    </tab-content>
                    <tab-content icon="uil uil-images" class="mb-5" :before-change="validateTab2">

                            <div class="row">
                                <div class="col-12 mb-4">
                                    <h5 class="d-inline">Choose media from sponsor:</h5>
                                    <b-button class="mb-2 d-inline float-end" variant="primary" title="Add Sponsor Media" @click="callModalAddSponsorMedia()">
                                        <i class="uil-images"></i> Add Media
                                    </b-button>
                                    <span class="col-12 small text-danger d-inline-block">{{errMessage2}}</span>


                                         <div class="row mt-2">
                                             <div class="col-12">
                                                <h5>Media</h5>
                                                 <!--  <b-button class="mb-2 d-inline float-end" variant="primary" title="Add Sponsor Media" @click="callModalAddSponsorMedia()">
                                                        <i class="uil-images"></i> Add Media
                                                    </b-button><br><br><br> -->
                                                 <div  v-for="(item, index) in advertisementData.media" :key="index" class="mb-4">

                                                         <div class="row">
                                                           <div class="col-6">
                                                             <h5>{{item.name}}</h5>

                                                              <template>
                                                                 <b-dropdown variant="success" left  >
                                                                   <template #button-content class="mb-2">
                                                                     Select Media<i class="mdi mdi-chevron-down me-2 "></i>
                                                                   </template>

     <b-dropdown-item
    v-for="(item2, index2) in dropdown_media[index]"
    :key="index2 + item2.text"
    :style="{ backgroundColor: (item2.attributes && item2.attributes.color) ? item2.attributes.color : '#f8f9fa' }"
    @click="selectImage(index, item2)">
    {{ item2.text }}
    <img width="70px" class="img-fluid table-images" :src="item2.source_url" />
</b-dropdown-item>
                                                                 </b-dropdown>
                                                               </template>

 <div :id="'image_' + index" class="mt-2" :style="{ backgroundColor: selectedMediaColors[index] || '#f8f9fa' }">
    <img class="img-fluid table-images" :src="dropdown_media[index][0].source_url" />
</div>
</div>
    <div class="col-6">
                            <h5>Add Attributes for {{ item.name }}:</h5>
                            <h5>Add Attributes for {{ item.name }}:</h5>
   
 <h5>Add Attributes for {{ item.name }}:</h5>
   
   <div v-for="(attribute, index_attribute) in item.attributes" :key="index_attribute">
<b-form-group :label="index_attribute" label-for="attributename">
    <b-form-input
        v-if="index_attribute === 'color'"
        :id="index+delimiter+index_attribute"
        :name="index_attribute"
         :value="attribute"
        type="color"
          @input="updateColor(index, $event)"
           ></b-form-input>

    <b-form-input
      v-else
      :id="index+delimiter+index_attribute" 
      :name="index_attribute" 
     :value="attribute"
      class="form-control"
    ></b-form-input>
  </b-form-group>
</div>
    

                                                                <!--<div v-if="Object.keys(item.attributes).length" >
                                                                      <div  v-for="(attribute, index_attribute) in item.attributes" :key="index_attribute">

                                                                            <b-form-group :label="attribute.name" label-for="attributename">
                                                                                  <b-form-input :id="index+delimiter+index_attribute" :name="index_attribute"> </b-form-input>
                                                                            </b-form-group>
                                                                      </div>
                                                                </div>
                                                                <div v-else>
                                                                    No attributes needed
                                                                </div> -->



                                                            </div>

                                                       </div>
                                                  </div>
                                             </div>
                                        </div>



                                </div>
                            </div>




                    </tab-content>
                    <tab-content icon="mdi mdi-checkbox-marked-circle-outline" >
                        <div class="row">
                            <div class="col-12">
                                <h5 class="mb-5">Advertisement Overview:</h5>
                            </div>
                        </div>


                        <div class="row" :key="thirdTabKey">
                            <div class="col-12 text-center">
                                <b-card no-body>
                                    <b-card-body>
                                        <b-card-title class="text-center">

                                            <h4 class="mb-3">{{advertisementData.placement_name}}</h4>
                                            <h6 class="card-subtitle text-muted">
                                                Status:
                                                  <span v-html="format_status(placement_status == 1 ? 'active' : 'inactive', 'font-size-12')"></span>
                                            </h6>
                                        </b-card-title>

                                        <div class="sponsor-logo-grid-small text-center">
                                            <img class="img-fluid" :src="sponsor.logo" />
                                        </div>

                                        <h5>Attributes:</h5>

                                       <ul v-if="placement_attributes.length > 0" class="d-inline-block">
                                            <li class="text-start" v-for="(attribute, index) in placement_attributes" :key="index"><strong>{{attribute.key}}: {{attribute.value}}</strong></li>
                                        </ul>
                                        <p v-else>No attributes added</p>

                                    </b-card-body>
                                </b-card>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-12">
                                <h5 class="mt-2">Media Files:</h5>
                            </div>
                        </div>

                        <div class="row">

                             <div  v-for="(item, index) in structure_media" :key="'structure_media'+index" class="col-4 mb-4">
                                       <ul style=" list-style: none;"><li style=" display: inline-block;  margin-right: 10px;"> <h5>{{item.name}}</h5>

                                         <!--<div  v-for="(item_selected_image) in dropdown_media_selected" :key="+item_selected_image.value.source_url" >
                                                <div v-if="item_selected_image.key == index" class="col-sm- d-inline-block">
                                                    <img width="100%" class="img-fluid img-thumbnail table-images" :src="item_selected_image.value.source_url">
                                                </div>
                                         </div> -->

                                          <div  v-for="(item_media_attributes) in media_attributes" :key="item_media_attributes.name" class="">
                                               <div v-if="item_media_attributes.image == index" >
                                                       {{item_media_attributes.key}}: {{item_media_attributes.value}}
                                               </div>
                                          </div>


                                       </li>
                                    </ul>
                             </div>
                         </div>



                    </tab-content>

                    <template slot="finish">
                        <div slot="wizard-finish">
                            <button
                                    class="btn btn-primary wizard-btn-custom"
                                    :is-complete="tryingToEdit"
                                    @click="editAdvertisement"
                                    :disabled="tryingToEdit"
                            > <b-spinner v-show="tryingToEdit" small></b-spinner>Submit
                            </button>
                        </div>
                    </template>

                </form-wizard>
            </div>
        </div>
        <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center" v-if="showLoader">
                <b-spinner large></b-spinner>
            </div>
        </div>

        <modalAddSponsorMedia :sponsor_id="sponsor_id" @refreshScreen="onChangeSponsor" ></modalAddSponsorMedia>
        <ModalAddAdvertisementMediaAttributes @setMediaAttributes="getMediaWithAttributes" :item="mediaItem"></ModalAddAdvertisementMediaAttributes>
        <ModalAddAdvertisementAttributes @setAdvertisementAttributes="getAdvertisementWithAttributes" :item="attributes" :placement_type_id="placement_type_id"></ModalAddAdvertisementAttributes>

    </b-modal>

</template>